/**
* CUSTOM CSS TRUETEMP
*/

.min-w-300 {
    min-width: 300px;
}

.min-h-200 {
    min-height: 300px;
}

.pointer {
    cursor: pointer;
}
